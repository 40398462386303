import { Component, EventEmitter, Output, TemplateRef } from '@angular/core';
import { map, Observable } from 'rxjs';
import { NEW_ITEM_KEY } from 'src/app/shared-module/models/new-item-key';

import { SlideoutSaveRevertViewModel } from '../../shared-module/save-revert/view-models/slide-out-save-revert-vm';
import { SlideOutContainerAction } from '../models/constants/slide-out-container-action';
import { SlideOutFooterItem, SlideOutFooterSection } from '../models/slide-out-footer-item';
import { SlideOutContainerService } from '../slide-out-container.service';

@Component({
  selector: 'app-slide-out-footer',
  templateUrl: './slide-out-footer.component.html'
})
export class SlideOutFooterComponent {
  @Output() public triggerAction = new EventEmitter<SlideOutContainerAction>();

  public showExtraRightItems: boolean = true;

  public extraLeftItems: Array<TemplateRef<any>> = [];
  public extraCenterItems: Array<TemplateRef<any>> = [];
  public extraRightItems: Array<TemplateRef<any>> = [];
  public showSave: Observable<boolean> = this.slideOutService.readOnly.pipe(map((readonly) => !readonly));

  public saveRevertVm = new SlideoutSaveRevertViewModel({
    isNew: this.slideOutService.optionId.pipe(map((x) => x === NEW_ITEM_KEY)),
    dirty: this.slideOutService.dirty,
    valid: this.slideOutService.isFormValid,
    saveButtonText: this.slideOutService.saveButtonText,
    onSave: (): void => {
      this.triggerAction.emit(SlideOutContainerAction.Save);
      this.showExtraRightItems = false;
    },
    onRevert: (): void => this.triggerAction.emit(SlideOutContainerAction.Revert),
    onSaveComplete: (): void => {
      this.showExtraRightItems = true;
    }
  });

  public constructor(private slideOutService: SlideOutContainerService) {
    this.slideOutService.extraFooterItems.subscribe((x) => {
      this.extraLeftItems = this.getFooterItemsByPosition(x, SlideOutFooterSection.Left);
      this.extraCenterItems = this.getFooterItemsByPosition(x, SlideOutFooterSection.Center);
      this.extraRightItems = this.getFooterItemsByPosition(x, SlideOutFooterSection.Right);
    });
  }

  private getFooterItemsByPosition(
    items: Array<SlideOutFooterItem>,
    position: SlideOutFooterSection
  ): Array<TemplateRef<any>> {
    return items.filter((y) => y.position == position).map((y) => y.template);
  }

  public onCancel(): void {
    this.triggerAction.emit(SlideOutContainerAction.Cancel);
  }
}
