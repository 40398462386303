<nav id="nav-menu" class="navbar navbar-expand navbar-light bg-light fixed-top">
  <div *ngIf="blockMenu()" class="e2g-backdrop"></div>
  <div class="navbar-brand pointer mr-4">
    <img id="img-logo" src="../assets/images/scra.svg" alt="SCRA Logo" (click)="navigateToLandingPage()" />
  </div>
  <div class="d-flex d-flex-nowrap justify-content-between w-100 align-items-center ml-3" id="navbarContent">
    <div class="primary-menu-left-collapsed">
      <ul class="navbar-nav align-items-center">
        <li class="nav-item dropdown" ngbDropdown id="li-menu-collapsed">
          <a ngbDropdownToggle class="nav-link" role="button" data-toggle="dropdown" id="menu-collapsed">Menu</a>
          <div class="dropdown-menu" ngbDropdownMenu>
            <a
              *ngIf="hasIdms | async"
              class="dropdown-item"
              routerLink="dashboard"
              id="menu-dashboard-c"
              routerLinkActive="active-link"
            >
              <span>Dashboard</span>
            </a>
            <a
              *ngIf="hasIdms | async"
              class="dropdown-item"
              routerLink="assets"
              id="menu-assets-c"
              routerLinkActive="active-link"
            >
              <span>Assets</span>
            </a>
            <li ngbDropdown class="nav-item dropright" id="menu-reports-c">
              <a role="button" ngbDropdownToggle>Reports</a>
              <div class="dropdown-menu" aria-labelledby="selectCompanyDropdown" ngbDropdownMenu>
                <ng-container *ngFor="let reportOption of reportOptions | async">
                  <div *ngIf="reportOption.hasTopDivider" class="dropdown-divider"></div>
                  <a
                    *ngIf="(reportOption.visible | async) ?? true"
                    class="dropdown-item pointer"
                    ngbDropdownItem
                    [disabled]="(reportOption.disabled | async) == true"
                    (click)="reportOption.action()"
                    id="menu-{{ reportOption.id }}"
                  >
                    {{ reportOption.label }}
                  </a>
                </ng-container>
              </div>
            </li>
            <a class="dropdown-item" routerLink="sage" id="menu-sage-c" routerLinkActive="active-link">
              <span>SAGE</span>
            </a>
            <a
              class="dropdown-item"
              href="https://damage.e2g.com"
              target="_blank"
              rel="noopener noreferrer"
              id="menu-damage-c"
            >
              <span>Damage<fa-icon [icon]="faArrowUpRightFromSquare" size="xs" class="ml-2"></fa-icon></span>
            </a>
            <a
              class="dropdown-item"
              href="https://practices.e2g.com"
              target="_blank"
              rel="noopener noreferrer"
              id="menu-practices-c"
            >
              <span>Practices<fa-icon [icon]="faArrowUpRightFromSquare" size="xs" class="ml-2"></fa-icon></span>
            </a>
          </div>
        </li>
      </ul>
    </div>
    <div class="primary-menu-left">
      <ul class="navbar-nav align-items-center">
        <li *ngIf="hasIdms | async" class="nav-item">
          <a routerLink="dashboard" id="menu-dashboard" routerLinkActive="active-link" class="menuItemHover"
            >Dashboard</a
          >
        </li>
        <li *ngIf="hasIdms | async" class="nav-item">
          <a routerLink="assets" id="menu-assets" class="menuItemHover" routerLinkActive="active-link">Assets</a>
        </li>
        <li class="nav-item dropdown" ngbDropdown id="menu-reports">
          <a ngbDropdownToggle class="nav-link" role="button" data-toggle="dropdown" class="menuItemHover">Reports</a>
          <div class="dropdown-menu" ngbDropdownMenu>
            <ng-container *ngFor="let reportOption of reportOptions | async">
              <div *ngIf="reportOption.hasTopDivider" class="dropdown-divider"></div>
              <a
                *ngIf="(reportOption.visible | async) ?? true"
                class="dropdown-item pointer"
                ngbDropdownItem
                [disabled]="(reportOption.disabled | async) == true"
                (click)="reportOption.action()"
                id="menu-{{ reportOption.id }}"
              >
                {{ reportOption.label }}
              </a>
            </ng-container>
          </div>
        </li>
        <li class="nav-item">
          <a routerLink="sage" id="menu-sage" class="menuItemHover" routerLinkActive="active-link">SAGE</a>
        </li>
        <li class="nav-item">
          <a
            href="https://damage.e2g.com"
            target="_blank"
            rel="noopener noreferrer"
            id="menu-damage"
            class="menuItemHover"
            >Damage<fa-icon [icon]="faArrowUpRightFromSquare" size="xs" class="ml-2"></fa-icon
          ></a>
        </li>
        <li class="nav-item">
          <a
            href="https://practices.e2g.com"
            target="_blank"
            rel="noopener noreferrer"
            id="menu-practices"
            class="menuItemHover"
            >Practices<fa-icon [icon]="faArrowUpRightFromSquare" size="xs" class="ml-2"></fa-icon
          ></a>
        </li>
      </ul>
    </div>
    <div class="primary-menu-right">
      <ul class="navbar-nav align-items-center">
        <li class="nav-item rightSideMenuIcons">
          <a class="top-link" role="button" id="menu-help" (click)="openHelp()">
            <fa-icon [icon]="helpTicketIcon"></fa-icon>
          </a>
        </li>
        <li class="nav-item dropdown active rightSideMenuIcons" ngbDropdown id="li-question">
          <div>
            <a
              class="top-link"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              id="menu-question"
              ngbDropdownToggle
            >
              <fa-icon [icon]="faCircleQuestion"></fa-icon>
            </a>
          </div>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="userMenuDropdown" ngbDropdownMenu>
            <a class="dropdown-item pointer" routerLink="release-notes" id="menu-release-notes" ngbDropdownItem>
              <fa-icon [icon]="faBook" [fixedWidth]="true" class="mr-2"></fa-icon>
              Release Notes
              <!-- uncomment line below when submenu has multiple items -->
              <!-- <span *ngIf="releaseNoteNewCount > 0" class="fa-layers-counter link-notification-offset">{{
                releaseNoteNewCount
              }}</span> -->
            </a>
          </div>
          <span *ngIf="releaseNoteNewCount > 0" class="fa-layers-counter">{{ releaseNoteNewCount }}</span>
        </li>
        <li class="nav-item dropdown active rightSideMenuIcons" ngbDropdown #menu="ngbDropdown" id="li-settings">
          <div>
            <a
              class="top-link"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              id="menu-options"
              ngbDropdownToggle
            >
              <fa-icon [icon]="faGear"></fa-icon>
            </a>
          </div>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="userMenuDropdown" ngbDropdownMenu>
            <div class="dropdown-menu-settings">
              <a class="dropdown-header" ngbDropdownItem disabled>
                {{ userName | async }}
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-header" ngbDropdownItem disabled>
                <strong>Client</strong>&nbsp;Version:&nbsp;{{ clientVersionInfo | async }}
              </a>
              <a class="dropdown-header" ngbDropdownItem disabled>
                <strong>Server</strong>&nbsp;Version:&nbsp;{{ serverVersionInfo | async }}
              </a>
              <ng-container *ngIf="hasSettingsAccess | async">
                <div class="dropdown-divider"></div>
                <a class="dropdown-item pointer" routerLink="/settings" id="menu-settings" ngbDropdownItem>
                  <fa-icon [icon]="faCog" [fixedWidth]="true" class="mr-2"></fa-icon>
                  Settings
                </a>
              </ng-container>
              <ng-container *ngIf="canImport | async">
                <div class="dropdown-divider"></div>
                <a
                  class="dropdown-item"
                  routerLink="import"
                  id="menu-import-c"
                  routerLinkActive="active-link"
                  ngbDropdownItem
                  [disabled]="(importDisabled | async) == true"
                >
                  <fa-icon [icon]="faFileImport" [fixedWidth]="true" class="mr-2"></fa-icon>
                  Import/Export
                </a>
              </ng-container>
              <ng-container *ngIf="isAdmin | async">
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" routerLink="/admin/logs" id="menu-app-logs" ngbDropdownItem>
                  <fa-icon [icon]="faFileLines" [fixedWidth]="true" class="mr-2"></fa-icon>
                  Application Logs
                </a>
              </ng-container>

              <div class="dropdown-divider"></div>

              <li
                *ngIf="!(selectorDisabled | async) && !(selectorHidden | async); else companyDisabled"
                id="menu-company"
              >
                <e2g-select-input
                  id="select-companies"
                  class="w-100"
                  [(ngModel)]="selectedCompanyKey"
                  [options]="companies"
                  (change)="onCompanyChange(); menu.close()"
                >
                </e2g-select-input>
              </li>

              <div class="dropdown-divider"></div>

              <a class="dropdown-item pointer" (click)="logout()" id="menu-logout" ngbDropdownItem>
                <fa-icon [icon]="faSignOutAlt" [fixedWidth]="true" class="mr-2"></fa-icon>
                Logout
              </a>
            </div>
          </div>
        </li>
        <li
          id="menu-notifications"
          class="nav-item top-link rightSideMenuIcons"
          (click)="toggleNotifications($event)"
          [matTooltip]="((state | async)?.notifications)!.tooltip"
        >
          <div class="fa-layers fa-fw">
            <fa-icon
              [icon]="((state | async)?.notifications)!.display ? faSolidBell : faBell"
              [fixedWidth]="true"
            ></fa-icon>
            <span
              *ngIf="notificationCount | async as localNotificationCount"
              class="fa-layers-counter fa-layers-top-right"
              >{{ localNotificationCount }}</span
            >
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>

<ng-template #companyDisabled>
  <li class="dropdown-header" id="menu-company-disabled">
    <fa-icon [icon]="faChevronLeft" [fixedWidth]="true" [ngClass]="{ 'fa-blank': selectorHidden | async }"></fa-icon>
    {{ selectedCompanyName }}
  </li>
</ng-template>
