export const requiredMessage = (): string => 'Cannot be blank';

export const lengthMessage = (charLimit: number): string => `Limited to ${charLimit} characters`;

export const validCharactersMessage = (validationString: string): string => `Can only include ${validationString}`;

export const valueInListMessage = (name: string): string => `${name} already exists`;

export const futureDateMessage = (): string => 'Cannot define future dates';

export const valuePostiveIntegerMessage = (): string => 'Must enter a positive integer';

export const valuePostiveNumberMessage = (): string => 'Must enter a positive number';

export const latitudeCoordinateMessage = (): string =>
  'The latitude coordinate(##.######) must be between -90.000000 and 90.000000 degrees.';

export const longitudeCoordinateMessage = (): string =>
  'The longitude coordinate(###.######) must be between -180.000000 and 180.000000 degrees.';

export const latitudeCoordinateFormatMessage = (): string => 'The latitude coordinate format should be ##.######.';

export const longitudeCoordinateFormatMessage = (): string => 'The longitude coordinate format should be ###.######.';

export const valueMustBeUniqueMessage = (): string => 'Value must be unique.';

export function duplicateItemNameMessage(name: string): string {
  return `A ${name} with the same name already exists`;
}
export const duplicateCmlMessage = 'A CML with the same name, component, and group already exists';

export const circuitMissingFluidMessage = 'Group requires fluid stream selection for RBI calculation';
