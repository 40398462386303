import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UnitOfMeasure } from 'src/app/models/enums/unit-of-measure';

@Injectable({
  providedIn: 'root'
})
export class SageNavService {
  public constructor(private router: Router) {}

  public navSageModule(
    moduleId: string,
    assetId?: string,
    unitId?: string,
    unitOfMeasure?: UnitOfMeasure,
    componentId?: string,
    prd?: boolean
  ): void {
    this.router.navigate([
      makeSageModuleRouteString({
        moduleId,
        componentId,
        assetId,
        unitId,
        prd,
        unitOfMeasure: unitOfMeasure == undefined ? undefined : UnitOfMeasure[unitOfMeasure]
      })
    ]);
  }

  public navSageModuleFromRouteData(moduleRouteData: ModuleRouteData, index?: number[]): void {
    const routeParam: any[] = [makeSageModuleRouteString(moduleRouteData)];

    if (index && index.length > 0) {
      routeParam.push({ index: JSON.stringify(index) });
    }

    this.router.navigate(routeParam);
  }
}

export interface ModuleRouteData {
  moduleId: string;
  componentId?: string;
  assetId?: string;
  unitId?: string;
  unitOfMeasure?: string;
  prd?: boolean;
}

const delimeter = '_';
const keyValueSeparator = ':';

export function makeSageModuleRouteString(data: ModuleRouteData): string {
  let route = 'sage/module/';

  if (data.assetId) {
    if (data.componentId) {
      route = 'assets/sage/expertview/';
    } else if (data.prd) {
      route = 'assets/sage/prdrbi/';
    } else {
      route = 'assets/sage/module/';
    }
  }

  let key: keyof ModuleRouteData;
  for (key in data) {
    const value = data[key];

    //null sometimes comes back from api
    if (value !== undefined && value !== null) {
      route += key + keyValueSeparator + value + delimeter;
    }
  }

  return route;
}
