import { Component, Input } from '@angular/core';
import { faArrowRotateLeft, faFloppyDisk } from '@fortawesome/pro-regular-svg-icons';

import { ISaveRevertViewModel } from './view-models/i-save-revert-vm';

@Component({
  selector: 'app-save-revert',
  templateUrl: './save-revert.component.html'
})
export class SaveRevertComponent {
  @Input() public vm!: ISaveRevertViewModel;

  public saveIcon = faFloppyDisk;
  public revertIcon = faArrowRotateLeft;
}
