import { Platform } from '@angular/cdk/platform';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DateAdapter, MAT_DATE_LOCALE, MatNativeDateModule, NativeDateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { DateOnlyUtility, E2gNgUiModule } from '@equityeng/e2g-ng-ui';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { InspectionPlanningModule } from '../inpection-planning-module/inpection-planning.module';
import { ToastModule } from '../shared/toast-module/toast.module';
import { AssetGoverningInspectionSettingsComponent } from './asset-governing-inspection-settings/asset-governing-inspection-settings.component';
import { BooleanRadioButtonsComponent } from './boolean-radio-buttons/boolean-radio-buttons.component';
import { ButtonSelectComponent } from './button-select/button-select.component';
import { CardButtonComponent } from './card-button/card-button.component';
import { CheckboxMultiColumnComponent } from './checkbox-multi-column/checkbox-multi-column.component';
import { CheckboxMultiselectComponent } from './checkbox-multiselect/checkbox-multiselect.component';
import { ClickOutsideDirective } from './chick-outside.directive';
import { DialogHostDirective } from './dialog-host.directive';
import { DialogComponent } from './dialog/dialog.component';
import { NameDialogComponent } from './name-dialog/name-dialog.component';
import { SanitizePipe } from './pipes/SanitizePipe';
import { SaveRevertComponent } from './save-revert/save-revert.component';
import { SlideInputComponent } from './slide-input/slide-input.component';

export class AppDateAdapter extends NativeDateAdapter {
  public format(date: Date): string {
    return DateOnlyUtility.formatAsE2gDate(date);
  }
}
@NgModule({
  declarations: [
    DialogComponent,
    CardButtonComponent,
    DialogHostDirective,
    ClickOutsideDirective,
    SlideInputComponent,
    CheckboxMultiselectComponent,
    NameDialogComponent,
    AssetGoverningInspectionSettingsComponent,
    CheckboxMultiColumnComponent,
    BooleanRadioButtonsComponent,
    SanitizePipe,
    ButtonSelectComponent,
    SaveRevertComponent
  ],
  imports: [
    ScrollingModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatDividerModule,
    MatMenuModule,
    NgbModule,
    CommonModule,
    FormsModule,
    FontAwesomeModule,
    MatAutocompleteModule,
    PdfViewerModule,
    MatSlideToggleModule,
    E2gNgUiModule,
    InspectionPlanningModule,
    MatRadioModule
  ],
  exports: [
    ScrollingModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatTreeModule,
    MatDividerModule,
    MatMenuModule,
    NgbModule,
    FontAwesomeModule,
    DialogComponent,
    MatAutocompleteModule,
    CardButtonComponent,
    ClickOutsideDirective,
    SlideInputComponent,
    MatSlideToggleModule,
    CheckboxMultiselectComponent,
    NameDialogComponent,
    AssetGoverningInspectionSettingsComponent,
    CheckboxMultiColumnComponent,
    BooleanRadioButtonsComponent,
    ToastModule,
    SanitizePipe,
    ButtonSelectComponent,
    SaveRevertComponent
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: AppDateAdapter,
      deps: [MAT_DATE_LOCALE, Platform]
    }
  ]
})
export class SharedModule {}
