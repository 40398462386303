import { Observable } from 'rxjs';

import { ISaveRevertViewModel } from './i-save-revert-vm';

export interface SlideoutSaveRevertViewModelConfig {
  isNew: Observable<boolean>;
  dirty: Observable<boolean>;
  valid: Observable<boolean>;
  saveButtonText: Observable<string>;
  onSave: () => void;
  onRevert: () => void;
  onSaveComplete: () => void;
}

export class SlideoutSaveRevertViewModel implements ISaveRevertViewModel {
  private saving = false;
  private dirty = false;
  private valid = false;
  private isNew = false;
  public saveButtonText: string = 'Save';
  public constructor(private config: SlideoutSaveRevertViewModelConfig) {
    this.config.dirty.subscribe((dirty) => {
      this.dirty = dirty;
      if (!dirty) {
        this.savePossiblyCompleted();
      }
    });

    this.config.valid.subscribe((valid) => (this.valid = valid));
    this.config.isNew.subscribe((isNew) => {
      if (this.isNew && !isNew) {
        this.savePossiblyCompleted();
      }
      this.isNew = isNew;
    });

    this.config.saveButtonText.subscribe((saveButtonText) => (this.saveButtonText = saveButtonText));
  }

  private savePossiblyCompleted(): void {
    if (this.saving) {
      this.saving = false;
      this.config.onSaveComplete();
    }
  }

  public onSave(): void {
    this.saving = true;
    this.config.onSave();
  }

  public onRevert(): void {
    this.config.onRevert();
  }

  public get showRevert(): boolean {
    return this.dirty && !this.isNew;
  }

  public get saveDisabled(): boolean {
    return this.saving || !this.valid || (!this.dirty && !this.isNew);
  }

  public get revertDisabled(): boolean {
    return this.saving;
  }
}
