import { Injectable } from '@angular/core';
import { filter, map, Observable, startWith, take, timer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JiraWidgetService {
  public load(): void {
    //Adapted from https://community.atlassian.com/t5/Jira-questions/Re-Re-Hi-Does-someone-know-any-idea-to-embed-JSD-Widge/qaq-p/2759107/comment-id/1035163#M1035163
    const jhdScript = document.createElement('script');
    jhdScript.type = 'text/javascript';
    jhdScript.setAttribute('data-jsd-embedded', 'null');
    jhdScript.setAttribute('data-key', '282c535c-fd40-4df1-a61e-5092003e6bb0');
    jhdScript.setAttribute('data-base-url', 'https://jsd-widget.atlassian.com');
    jhdScript.src = 'https://jsd-widget.atlassian.com/assets/embed.js';

    jhdScript.onload = (): void => {
      const domContentLoadedEvent = new Event('DOMContentLoaded', {
        bubbles: true,
        cancelable: true
      });
      window.document.dispatchEvent(domContentLoadedEvent);
      this.observeIframe();
    };

    document.getElementsByTagName('head')[0].appendChild(jhdScript);
  }

  public openHelp(): void {
    this.findIframe().subscribe((iframe) => {
      const button = this.findHelpButtonInIframe(iframe);
      button!.click();
    });
  }

  private observeIframe(): void {
    this.findIframe().subscribe((iframe) => {
      const resizeObserver = new ResizeObserver((x) => {
        const currentIframe = x[0].target as HTMLIFrameElement;
        //The button only appears when the model is not open
        const button = this.findHelpButtonInIframe(currentIframe);
        if (button) {
          currentIframe.style.visibility = 'hidden';
        } else {
          currentIframe.style.visibility = '';
        }
      });
      resizeObserver.observe(iframe);
    });
  }

  private findIframe(): Observable<HTMLIFrameElement> {
    return timer(500).pipe(
      startWith(undefined),
      map(() => document.getElementById('jsd-widget') as HTMLIFrameElement | null),
      filter((iframe) => iframe != null),
      //There is a compile time error without this map
      map((iframe) => iframe!),
      take(1)
    );
  }

  private findHelpButtonInIframe(iframe: HTMLIFrameElement): HTMLButtonElement | null {
    const iframeContent = iframe.contentDocument;
    return iframeContent?.getElementById('help-button') as HTMLButtonElement | null;
  }
}
