import 'ag-grid-community';

import { enableProdMode, Injectable } from '@angular/core';
import { AuthConfigService, AuthService, TokenMode, UserFactory } from '@equityeng/auth';
import { FluidDesignerConfigService } from '@equityeng/fluid-designer';
import { GraphicsConfigService } from '@equityeng/graphics';
import { MaterialPickerConfigService } from '@equityeng/material-picker';
import { SageEnvironmentService } from '@equityeng/sage-forms-core';
import { LicenseManager } from 'ag-grid-enterprise';

import { PRODUCT_NAME } from '../models/auth-constants';

export class AppSettings {
  public version?: string;
  public graphicsEnabled?: boolean;
  public angularProduction: boolean = false;
  public fullStoryProduction: boolean = false;
  public fullStoryOrgId: string = '14GN23';
  public fullStroyAppName: string = 'scraDev';
  public apiUri?: string;
  public eecVisualizerUri?: string;

  public authority?: string;
  public redirectUri?: string;
  public postLogoutRedirectUri?: string;
  public silentRedirectUri?: string;
  public agGridLicense?: string;
}

@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {
  public settings: AppSettings = new AppSettings();

  public constructor(
    private authConfig: AuthConfigService,
    private service: AuthService,
    private materialPickerConfigService: MaterialPickerConfigService,
    private fluidDesignerConfig: FluidDesignerConfigService,
    private graphicsConfigService: GraphicsConfigService,
    private sageSettingsService: SageEnvironmentService
  ) {
    this.authConfig.config.authority = 'https://auth.e2g.com';
    this.authConfig.config.clientId = PRODUCT_NAME;
    this.authConfig.config.redirectUri = 'http://localhost:4200/auth-callback';
    this.authConfig.config.defaultUri = '/home';
    this.authConfig.config.postLogoutRedirectUri = 'http://localhost:4200';
    this.authConfig.config.silentRedirectUri = 'http://localhost:4200/assets/auth/html/silent-auth-callback.html';
    this.authConfig.config.scopes = [
      'openid',
      'offline_access',
      'e2g.scra-api',
      'e2g.materials-api',
      'e2g.fluid-api',
      'e2g.parallel-calc-api'
    ];
    this.authConfig.config.requiredProducts = [PRODUCT_NAME];
    this.authConfig.config.tokenMode = TokenMode.AccessToken;
    this.authConfig.config.userFactoryClass = UserFactory;
    this.authConfig.config.enableDebugLogging = false;
    this.authConfig.config.enableAuthorization = false;
    this.authConfig.config.applicationKey = 'scra';
  }

  public updateSettings(appSettings: AppSettings): void {
    Object.keys(appSettings)
      .filter((key) => key in this.authConfig.config)
      .forEach((key) => {
        const appSettingsKey = key as keyof AppSettings;
        (this.authConfig.config as any)[appSettingsKey] = appSettings[appSettingsKey];
      });

    Object.keys(appSettings).forEach((key) => {
      const appSettingsKey = key as keyof AppSettings;
      (this.settings[appSettingsKey] as any) = appSettings[appSettingsKey];
    });

    this.service.setupService();
    if (this.settings.agGridLicense) {
      LicenseManager.setLicenseKey(this.settings.agGridLicense);
    }

    this.enableProduction(this.settings);

    this.materialPickerConfigService.materialServiceUri = `${this.settings.apiUri!}/proxy/materials`;
    this.fluidDesignerConfig.fluidServiceUri = `${this.settings.apiUri!}/proxy/fluids`;
    this.graphicsConfigService.graphicsUrl = `${this.settings.apiUri!}/proxy/graphics`;
    this.graphicsConfigService.dimensionsEnabled = this.settings.graphicsEnabled ?? false;
    this.sageSettingsService.featureSet = this.settings.graphicsEnabled ? ['solidmodels'] : [];
  }

  private enableProduction(settings: AppSettings): void {
    if (settings.angularProduction.toString().toLowerCase() === 'true') {
      enableProdMode();
    }
  }
}
