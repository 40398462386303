<div *ngIf="equipment; else loading" class="h-100 flex-col flex-111">
  <div class="d-flex flex-col headerBackground">
    <label class="headingAssetName m-0 p-0 pt-3 pl-4">{{ assetName }}</label>
    <app-breadcrumbs class="breadcrumbs" [breadcrumbs]="breadcrumbs"></app-breadcrumbs>

    <ul
      ngbNav
      #nav="ngbNav"
      [(activeId)]="activeTabId"
      (navChange)="onBeforeSwitching($event)"
      class="nav-tabs header-table"
    >
      <li [ngbNavItem]="assetDetailTabs.Asset">
        <a ngbNavLink>Asset</a>
        <ng-template ngbNavContent>
          <div class="h-100 flex-col">
            <div class="flex-111 overflow-auto">
              <app-equip-data class="w-100" [assetId]="equipment!.id" *ngIf="!isPrd()"></app-equip-data>
              <app-prd-data class="w-100" *ngIf="isPrd()"></app-prd-data>
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="assetDetailTabs.Components" *ngIf="!isPrd()">
        <a ngbNavLink>Components</a>
        <ng-template ngbNavContent>
          <div class="h-100 flex-col">
            <div class="flex-111 overflow-auto">
              <app-comp [equipmentType]="equipment!.type" [breadcrumbs]="breadcrumbs"></app-comp>
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="assetDetailTabs.Groups" *ngIf="!isPrd()">
        <a ngbNavLink>Groups</a>
        <ng-template ngbNavContent>
          <div class="h-100 flex-col">
            <div class="flex-111 overflow-auto">
              <app-circuit [breadcrumbs]="breadcrumbs"></app-circuit>
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="assetDetailTabs.Cmls" *ngIf="!isPrd()">
        <a ngbNavLink>CMLs</a>
        <ng-template ngbNavContent>
          <app-cml [equipmentType]="equipment!.type" [breadcrumbs]="breadcrumbs"></app-cml>
        </ng-template>
      </li>
      <li [ngbNavItem]="assetDetailTabs.Activities">
        <a ngbNavLink>Activities</a>
        <ng-template ngbNavContent>
          <div class="d-flex h-100 flex-col">
            <app-asset-activities class="h-100" [breadcrumbs]="breadcrumbs"></app-asset-activities>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="assetDetailTabs.Findings">
        <a ngbNavLink>Findings</a>
        <ng-template ngbNavContent>
          <div class="h-100 flex-col">
            <div class="flex-111 overflow-auto">
              <app-asset-findings [assetType]="equipment!.type" [breadcrumbs]="breadcrumbs"></app-asset-findings>
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="assetDetailTabs.Comments">
        <a ngbNavLink>Comments</a>
        <ng-template ngbNavContent>
          <div class="h-100 flex-col">
            <div class="flex-111 overflow-auto">
              <app-comments
                [parentId]="inputData.equipmentKey"
                [type]="commentType"
                [readonly]="commentsReadonly"
              ></app-comments>
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="assetDetailTabs.Configuration" *ngIf="!isPrd() || isPrdConfigurationVisible()">
        <a ngbNavLink>Configuration</a>
        <ng-template ngbNavContent>
          <div class="h-100 flex-col">
            <div class="flex-111 overflow-auto">
              <app-configuration [assetType]="equipment!.type" *ngIf="!isPrd()"></app-configuration>
              <app-prd-configuration
                [(rbi)]="equipment!.rbi!"
                *ngIf="isPrdConfigurationVisible()"
              ></app-prd-configuration>
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="assetDetailTabs.Rbi" *ngIf="isPrdRbiVisible()">
        <a ngbNavLink>RBI</a>
        <ng-template ngbNavContent>
          <div class="h-100 flex-col">
            <div class="h-100 flex-111 overflow-auto">
              <app-prd-rbi></app-prd-rbi>
            </div>
          </div>
        </ng-template>
      </li>
    </ul>
  </div>
  <div [ngbNavOutlet]="nav" class="h-100 flex-col flex-111"></div>
</div>

<ng-template #loading>
  <div class="loading"></div>
</ng-template>
