import { Injectable } from '@angular/core';
import { forkJoin, map, Observable, of } from 'rxjs';
import { AssetDetailTabs } from 'src/app/asset-module/detail/detail.component';
import { BreadcrumbsService } from 'src/app/breadcrumb-module/breadcrumbs.service';
import { Breadcrumb } from 'src/app/breadcrumb-module/breadcrumbs/breadcrumbs.component';
import { CalculationOutputStatus } from 'src/app/models/enums/calculation-output-status';
import { AssetDetailsNavService } from 'src/app/shared-module/services/asset-details-nav.service';
import { RouteParamsService } from 'src/app/shared-module/services/route-params.service';
import { ModuleRouteData } from 'src/app/shared-module/services/sage-nav.service';
import { StateService } from 'src/app/state.service';

import { SageSavePacket } from '../../models/sage-save-packet';
import { SageModuleDataService } from '../sage-module-data.service';
import { ISageModuleService } from '../sage-module-service';

@Injectable()
export class PrdRbiModuleService implements ISageModuleService {
  private sageRouteData!: ModuleRouteData;
  public isForComponent: boolean = false;
  public constructor(
    private breadcrumbsService: BreadcrumbsService,
    private dataService: SageModuleDataService,
    private stateService: StateService,
    private assetDetailsNavService: AssetDetailsNavService,
    private paramsService: RouteParamsService
  ) {
    this.paramsService.getSageRouteData().subscribe((sageRouteData) => (this.sageRouteData = sageRouteData));
  }

  public getBreadcrumbs(name: string, moduleType: string): Observable<Breadcrumb[]> {
    return forkJoin([this.breadcrumbsService.getAssetBreadcrumbs(this.sageRouteData.assetId!)]).pipe(
      map(([assetBcs]) => {
        assetBcs[2].linkCommand = (): void => {
          this.stateService.setFlowForHeirarchyTree(assetBcs[0].id, assetBcs[1].id, assetBcs[2].id);
          this.goBack(AssetDetailTabs.Rbi);
        };

        return assetBcs.concat({ name: `Expert View (${moduleType})` });
      })
    );
  }

  public save(sageSavePacket: SageSavePacket): Observable<boolean> {
    return this.dataService.savePrdModule(this.sageRouteData.moduleId, this.sageRouteData.assetId!, sageSavePacket);
  }
  public getCalcStatus(): Observable<string> {
    return of(CalculationOutputStatus.Calculated);
  }

  public routeBack(): void {
    this.goBack(AssetDetailTabs.Rbi);
  }

  private goBack(assetDetailsTab: AssetDetailTabs): void {
    this.assetDetailsNavService.navAssetDetails(
      this.sageRouteData.unitId!,
      this.sageRouteData.assetId!,
      this.sageRouteData.unitOfMeasure!,
      assetDetailsTab
    );
  }
}
