import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { CompanyService } from './company.service';
import { StateService } from './state.service';

@Injectable({
  providedIn: 'root'
})
export class RouteInspectionService {
  public constructor(
    private router: Router,
    private companyService: CompanyService,
    private stateService: StateService
  ) {}

  public init(): void {
    this.companyService.selectionChanging.subscribe(() => {
      this.stateService.clearFlow();
      this.stateService.clearSageTreePath();
      this.stateService.clearSelectedGridData();
      this.stateService.clearFilter();
      //Go back to default landing page
      this.router.navigate(['']);
    });
  }
}
